


































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiDistributionData,
    apiTopMemberEarnings,
    apiTopMemberFans
} from '@/api/distribution/distribution'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsPagination
    }
})
export default class CouponSurvey extends Vue {
    /** S Data **/
    earningsData = {} // 佣金概览数据统计
    //分销概览谁统计
    distributionData = {
        distribution_members: 0, // 分销会员数量
        distribution_member_percentage: 0 // 分销会员占比
    }
    topMemberData = [] // 分销会员排行榜列表
    topGoodsData = [] // 分销商品排行榜列表

    pagerTopMemberEarnings: RequestPaging = new RequestPaging()
    pagerTopMemberFans: RequestPaging = new RequestPaging()
    /** E Data **/

    // 分销商收入排行榜top50
    getTopEarnings(): void {
        this.pagerTopMemberEarnings
            .request({
                callback: apiTopMemberEarnings
            })
            .then((res: any) => {})
    }

    // 分销商下级人数排行榜top50
    getTopFans(): void {
        this.pagerTopMemberFans
            .request({
                callback: apiTopMemberFans
            })
            .then((res: any) => {})
    }

    /** S Life Cycle **/
    created() {
        this.getTopEarnings()
        this.getTopFans()

        apiDistributionData()
            .then(res => {
                this.earningsData = res.earnings_data
                this.distributionData = res.distribution_data
                this.topGoodsData = res.top_member_earings
                this.topMemberData = res.top_member_fans
            })
            .catch(() => {
                this.$message.error('请求数据失败，请刷新重载!')
            })
    }
    /** S Life Cycle **/
}
